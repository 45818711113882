.pzTransfersMainContainer {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  height: 78vh;
  border-radius: 10px;
  width: 85vw;
  box-sizing: border-box;
  overflow: hidden;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #343339;
  padding: 17px 24px;
}
.searchbarAndFilter {
  display: flex;
  gap: 11px;
}
.transferPaginationContainer {
  display: flex;
  gap: 20px;
}
.searchBarContainer {
  display: flex;
  width: 320px;
  border: 1px solid #cfdce5;
  border-radius: 6px;
  padding: 5px 9px 5px 24px;
}
.searchBar {
  color: #b3b3b3;
  width: 100%;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.filterButtonContainer {
  display: flex;
  margin: auto 0;
}
.filterButton {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.nextButton,
.previousButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
  border-radius: 4px;
  color: #99b2c6;
}
.nextButton {
  padding: 7px 50px;
}
.previousButton {
  padding: 7px 38px;
}
.nextButton:hover,
.previousButton:hover {
  color: #02aab0;
}
.pageNumber {
  font-family: "metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  color: #ffffff;
  margin: auto;
  text-align: center;
  @media screen and (max-width: 390px) {
    font-size: 12px;
  }
}
.disabledButton {
  cursor: not-allowed;
  background: #212c3d;
}
.disabledButton:hover {
  color: #99b2c6;
}
.bodyContainer {
  display: flex;
  flex: 1;
  height: 100%;
}
.transfersListMainContainer {
  flex: 1;
  border-right: 1px solid #343339;
  display: flex;
  flex-direction: column;
  padding: 29px 10px 30px 17px;
}
.transactionsDetailsMainContainer {
  flex: 1;
  padding: 32px 20px 20px 23px;
}
.txListHeadings {
  display: flex;
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 19px;
  margin-left: 7px;
  margin-right: 12px;
}
.txListDetails {
  display: flex;
  border-radius: 10px;
  background: rgba(217, 217, 217, 0.04);
  padding: 8px 0px 8px 7px;
  margin-bottom: 10px;
  margin-right: 9px;
}
.txListDetails:hover {
  cursor: pointer;
  background: rgba(2, 170, 176, 0.3);
}
.txListDetailsIfClicked {
  background: rgba(2, 170, 176, 0.3);
}
.txDateValue,
.txBeneficiaryValue,
.txSenderValue,
.txAmountValue,
.txIdValue,
.txFormattedDate,
.txFormattedTime {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word; // Allows long words to be broken and wrap
  overflow-wrap: break-word; // Modern version of word-wrap
  white-space: normal; // Allows text to wrap
}
.selectedTransferDetails {
  flex: 1;
  padding: 32px 20px 20px 23px;
}
.txListDetailsContainer {
  overflow-y: auto;
  margin-bottom: 80px;
}
.txListDetailsContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.txListDetailsContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  margin-top: 40px;
  border-radius: 10px;
}
.txAmountKey,
.txDateKey,
.txDateValue,
.txAmountValue,
.txIdValue,
.txIdKey {
  width: 18%;
}
.txSenderKey,
.txSenderValue {
  width: 30%;
}
.txBeneficiaryKey,
.txBeneficiaryValue {
  width: 23%;
}
.txFormattedTime {
  font-size: 10px;
}
.transactionsDetails {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.transactionAmount {
  color: #fff;
}
.usdAmount,
.inrAmount {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
}
.timestamp {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flagIcon {
  width: 14px;
  height: 13px;
}
.statusButtonProcessing {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #f9b959;
  border: none;
  border-radius: 5px;
}
.statusButtonCancelled {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #fd5252;
  border: none;
  border-radius: 5px;
}
.statusButtonCompleted {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #3b80df;
  border: none;
  border-radius: 5px;
}
.txDetailsKey {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.txDetailsValue {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.phoneContainer {
  text-align: right;
}
.nickNameContainer {
  width: 15%;
}
.accountNoContainer {
  width: 23%;
}
.paymentRefContainer {
  width: 22%;
}
.datePicker {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #ccc;
  }
}
.copyIcon {
  width: 25px;
  height: 16px;
}
.copyIcon:hover {
  cursor: pointer;
}
.valueAndCopyIconContainer {
  display: flex;
  align-items: center;
}
.copyIcon:hover path {
  color: #02aab0;
  stroke: #02aab0;
}
// src/pages/PZTransfers/index.module.scss

.link {
  color: #fff; // Example color for the link
  text-decoration: none; // Remove underline

  &:hover {
    text-decoration: underline; // Underline on hover
  }

  &:visited {
    color: #fff; // Example color for visited links
  }
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 35%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.refundButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "metrophobic";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border-radius: 4px;
  color: #99b2c6;
  padding: 7px 20px;
}
.refundButton:hover {
  color: #02aab0;
}
