.titleContainer {
  background: #243349;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #02aab0;
}

.bodyContainer {
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
}
