.loginTitle {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-align: center;
  padding-top: 21px;
  padding-bottom: 13px;
}
.loginTitleMob {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}
.loginUsingGoogleAccount {
  text-align: center;
  padding-top: 33px;
  padding-bottom: 23px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-left: 20px;
  margin-right: 20px;
}
.continueWithGoogleButton {
  padding: 9px 91px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.continueWithGoogleButton:hover {
  border: 1px solid #02aab0;
  color: #02aab0;
}
.continueWithGoogleButtonMob {
  margin-right: 20px;
  margin-left: 20px;
  padding: 9px 30px;
}
.continueWithGoogleButtonContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 57px;
}
.googleIcon {
  margin-right: 9px;
  margin-bottom: -5px;
}

.loginWithGoogleContainer {
  width: 477px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 70px;
  margin-top: 221px;
}
.loginWithGoogleContainerMob {
  width: 88%;
}
.cookiesConsentContainer {
  padding: 48px 8%;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  color: white;
  font-family: "metrophobic";
  padding-bottom: 30px;
}
