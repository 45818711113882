.spinnerOverlay {
  height: 80vh;
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.spinnerContainer {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
