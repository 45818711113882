.kycFixMainContainer {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  height: 78vh;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  width: 85vw;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 16px 32px;
  border-bottom: 1px solid #343339;
}
.kycFixHeading {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
}
.customerGuidValue,
.nameValue,
.createdValue,
.updatedValue,
.kycStatusValue,
.customerGuidKey,
.createdkey,
.updatedkey,
.namekey,
.kycStatuskey {
  width: 20%;
}
.resolveButton {
  font-family: "Metrophobic";
  font-size: 16px;
  font-weight: 400;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d43 100%);
  color: #fff;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 5px;
}
.resolveButton:hover {
  color: #02aab0;
}
.bodyContainer {
  padding: 31px 8px 20px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.headingKeys {
  display: flex;
  color: #a5a2b8;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px;
  margin-left: 11px;
  margin-right: 31px;
}
.valuesContainer {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 120px;
}
.valuesContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.valuesContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 10px;
}
.kycRejectedUsersDetails {
  padding: 8px 11px;
  background: rgba(217, 217, 217, 0.04);
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.spinnerOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.spinnerContainer {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.customerGuidValue,
.nameValue,
.createdValue,
.updatedValue,
.kycStatusValue {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1;
}
.tooltip:hover::before {
  visibility: visible;
  opacity: 1;
}
