.pzStatesTitle {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 125%;
  color: #ffffff;
  padding: 10px 22px;
}
.pzStatesContainer {
  padding: 30px 22px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.updateTrasuryButton,
.updateButton {
  padding: 10px 52px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border-radius: 4px;
  cursor: pointer;
}
.updateTrasuryButton:hover,
.updateButton:hover {
  color: #02aab0;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.popupContent {
  background: #081527;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  border-radius: 15px;
  width: 40%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  color: #fd5252;
  text-align: center;
  margin-top: 20px;
}
.spinnerOverlay {
  height: 50px;
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerContainer {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.currentStateDisplay {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 20px;
}
.currentState {
  color: #02aab0;
}
.descriptionContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.openDescription,
.closedDescription,
.restrictedDescription,
.invitationDescription {
  display: flex;
  gap: 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
}
.stateName {
  color: #02aab0;
}
.stateDescription {
  color: #ffffff;
}
.updateTrasuryButtonContainer {
  margin-top: 50px;
}
