.currencyChooseDiv {
  display: flex;
  flex-direction: row;
  gap: 24px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    gap: 20px;
    margin-right: 20px;
  }
}
.alertSpan {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
  margin-top: 8px;
}
.label {
  margin-left: 6px;
  cursor: pointer;
}
.radioButton {
  color: #ffffff;
  border: 1px solid #02aab0;
  border-radius: 8px;
  font-family: "Metrophobic";
  font-size: 18px;
  display: flex;
  line-height: 17px;
  cursor: pointer;
  padding: 10px 11px;
  @media only screen and (max-width: 992px) {
    width: 93%;
  }
  position: relative;

  // &:hover input[type="radio"],
  // input[type="radio"]:checked {
  //   opacity: 0.6; /* Show the circle */
  // }

  input[type="radio"]:hover,
  input[type="radio"]:checked {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transition: background-color 0.3s;
      background-color: #02aab0;
      opacity: 0.6; /* Show the circle */
    }
  }
}

.activeRadioButton {
  display: flex;
  border: 1px solid #02aab0;
  border-radius: 8px;
  line-height: 17px;
  font-family: "Metrophobic";
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
  padding: 10px 11px;
  background: linear-gradient(
    47.47deg,
    rgba(56, 230, 236, 0.2) 5.98%,
    rgba(51, 236, 205, 0.2) 85.82%
  );
  @media only screen and (max-width: 992px) {
    width: 93%;
  }
  position: relative;

  input[type="radio"],
  input[type="radio"]:checked {
    border-color: 1px solid #02aab0; /* Update the border color */
  }

  input[type="radio"]:checked::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #02aab0;
    transition: background-color 0.3s;
  }
}
input[type="radio"] {
  appearance: none;
  content: "";
  width: 12px;
  height: 12px;
  border: 1.5px solid #02aab0;
  border-radius: 50%;
  transition: 0.3s;
}
