.pzTransfersMainContainer {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 20px 20px 23px;
  width: 883px;
}
.transactionAmount {
  color: #fff;
}
.usdAmount,
.inrAmount {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
}
.inrAmount {
  width: 24%;
}
.receiverName {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}
.timestamp {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flagIcon {
  width: 14px;
  height: 13px;
}
.txDetailsKey {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.txIdValueContainer,
.txDetailsValue {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.phoneContainer {
  text-align: right;
}
.dropDownMainContainer {
  display: flex;
  gap: 30px;
}
.refundButton,
.searchButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "metrophobic";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border-radius: 4px;
  color: #99b2c6;
  padding: 7px 20px;
}
.refundButton:hover,
.searchButton:hover {
  color: #02aab0;
}
.statusButtonProcessing {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #f9b959;
  border: none;
  border-radius: 5px;
}
.statusButtonCancelled {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #fd5252;
  border: none;
  border-radius: 5px;
}
.statusButtonCompleted {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #3b80df;
  border: none;
  border-radius: 5px;
}
.errorMessage {
  font-family: "metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fd5252;
  margin-top: 30px;
}
.nickNameContainer {
  width: 14%;
}
.accountNoContainer {
  width: 20%;
}
.paymentRefContainer {
  width: 18.5%;
}
.exchangeRateContainer {
  width: 27%;
}
.txIdValueContainer {
  display: flex;
}
.copyIcon {
  width: 25px;
  height: 18px;
}
.copyIcon:hover {
  cursor: pointer;
}
.copyIcon:hover path {
  color: #02aab0;
  stroke: #02aab0;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 35%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
