.replyMessage {
  border: 1px solid #02aab0;
  background: transparent;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding: 9px 14px;
  height: 100px;
  margin-right: 15px;
  margin-left: 15px;
  width: 80%;
}
.sendReplyPopUpContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}
.textAreaContainer {
  display: flex;
  justify-content: center;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-right: 35px;
  margin-left: 35px;
  gap: 20px;
}
.sendButton {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border: 1px solid rgba(111, 111, 111, 0.3);
  border-radius: 4px;
  padding: 7px 0px;
  width: 50%;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}
.sendButton:hover {
  color: #02aab0;
}
.errorMessage {
  color: #fd5252;
  text-align: center;
  padding-top: 15px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
}
