@import "~@openfonts/museomoderno_vietnamese/index.css";
.headerMainContainer {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // width: 96%;
  padding-left: 28px;
  padding-right: 28px;
  border-bottom: 1px solid #343339;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #06152b;
  padding-bottom: 15px;
  padding-top: 15px;
  //margin-right: 40px;
  z-index: 5;
}

.headerMainContainerMob {
  padding-left: 20px;
  padding-right: 20px;
}
.fairexLogoMob {
  width: 38px;
}
.fairexLogo {
  width: 21px;
}
.radexText {
  font-family: "Exo";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 10px;
}
.logoContainer {
  display: flex;
  gap: 3px;
}
.docsButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
  background: transparent;
  border: none;
  cursor: pointer;
}
.docsButtonMob {
  font-size: 15px;
}
.docsButton:hover {
  color: #01c1ae;
}
.sendButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #01c1ae;
  background: transparent;
  border: none;
  padding-right: 30px;
  cursor: pointer;
}
.sendButtonMob {
  font-size: 15px;
  padding-right: 15px;
}
.loginButton {
  border: 2px solid #01c1ae;
  border-radius: 20px;
  background: transparent;
  margin-left: 30px;
  padding: 7px 43px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.loginButtonMob {
  border-radius: 16px;
  padding: 3px 20px;
  font-size: 15px;
  margin-left: 15px;
}
.loginButton:hover {
  color: #01c1ae;
}
.logoutButton {
  background: transparent;
  border: none;
  padding: 0;
  margin-right: 5px;
  cursor: pointer;
}
.logoutButton:hover .logoutIcon {
  -webkit-transform: scale(1);
  transform: scale(0.8);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.profileEmailContainer {
  border-radius: 5px;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f9fafa;
  margin-left: 18px;
  display: flex;
  height: 33px;
  justify-content: space-between;
}
.connectWalletContainer {
  display: flex;
}
.profileEmail {
  padding: 4px 9px;
  font-size: 16px;
  line-height: 21px;
}
.profileEmailMob {
  padding: 6px 10px;
}
.settingsButton {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 12px;
  cursor: pointer;
}

.hamburgerMenuClose {
  z-index: 10;
  background: transparent;
  border: none;
}
.hamburgerButton {
  background: transparent;
  border: none;
}
.profileSection {
  display: flex;
}
.userName {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #f3f2f8;
  width: 135px;
  height: 24px;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  margin-left: 18px;
  margin-bottom: 5px;
  padding-top: 4px;
  padding-bottom: 1px;
  padding-left: 9px;
  border-radius: 5px;
}
.profileImageContainer {
  margin: auto;
}
.profileImage {
  width: 50px;
}
