.react-datepicker {
  font-family: "Metrophobic" !important;
  font-size: 0.8rem !important;
  background-color: #384455 !important;
  color: #fff !important;
  border: 1px solid #384455 !important;
  border-radius: 4px !important;
}

.react-datepicker__header {
  text-align: center !important;
  background-color: #384455 !important;
  border-bottom: 1px solid #384455 !important;
  border-top-left-radius: 0.3rem !important;
  padding: 8px 0 !important;
  position: relative !important;
}

.react-datepicker__current-month {
  color: #fff !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.react-datepicker__day {
  color: #fff !important;
  width: 2rem !important;
  line-height: 2rem !important;
  margin: 0.2rem !important;
  border-radius: 4px !important;
}

.react-datepicker__day:hover {
  background-color: #02aab0 !important;
}

.react-datepicker__day--selected {
  background-color: #02aab0 !important;
  color: white !important;
}

.react-datepicker__day--selected:hover {
  background-color: #02aab0 !important;
}

.react-datepicker__day-name {
  color: #fff !important;
  width: 2rem !important;
  line-height: 2rem !important;
  margin: 0.2rem !important;
}

.react-datepicker__month-container {
  float: left !important;
}

.react-datepicker__week {
  white-space: nowrap !important;
}

.react-datepicker__day-names {
  margin-top: 10px !important;
}

.react-datepicker__navigation--previous {
  left: 1rem !important;
}

.react-datepicker__navigation--next {
  right: 1rem !important;
} 
.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #02aab0;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}

.react-time-picker {
  font-family: "Metrophobic" !important;
  font-size: 0.8rem !important;
  background: transparent !important;
  color: #fff !important;
}
.react-time-picker__wrapper {
  display: flex;
}
.react-time-picker__inputGroup {
  display: flex;
  align-items: center;
}

.react-time-picker__input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  color: #fff;
}
/* 
.react-time-picker__input:focus {
  outline: none;
  border-color: #02aab0;
} */

.react-time-picker__clock {
  color: #fff;
}
.react-time-picker__clear-button {
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}
.react-time-picker__clock-button {
  background: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}
