.statusSwitch {
  background: transparent;
  border: none;
  color: #02aab0;
}
.statusSwitch:hover {
  transform: scale(1.1);
}
.ticketChangeContainer {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 35%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.replyMessage {
  border: 1px solid #02aab0;
  background: transparent;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding: 9px 14px;
  height: 50px;
  margin-right: 10px;
}

.sendButton {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  padding: 5px 16px;
  border-radius: 4px;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}
.sendButton:hover {
  color: #02aab0;
}
.repliedMessagetooltip,
.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip::before,
.repliedMessagetooltip::before {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1;
}

.tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.repliedMessagetooltip::before {
  width: 200px;
  content: attr(data-tooltip);
  position: absolute;
  bottom: -35px;
  left: 20%;
  transform: translateX(-78%);
  text-wrap: wrap;
}
.repliedMessagetooltip:hover::before,
.tooltip:hover::before {
  visibility: visible;
  opacity: 1;
}
.editIcon {
  cursor: pointer;
}
.pzTicketsMainContainer {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  height: 78vh;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  width: 85vw;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 16px 32px;
  border-bottom: 1px solid #343339;
}
.pzTicketsHeading {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
}
.changeStatusButton {
  display: flex;
  gap: 5px;
  font-family: "Metrophobic";
  font-size: 16px;
  font-weight: 400;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d43 100%);
  color: #fff;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 5px;
}
.changeStatusButton:hover {
  color: #02aab0;
}
.checkbox-input {
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  outline: none;
  margin: 0;
  cursor: pointer;
  position: relative;
  background: #384455;
}

.checkbox-input:checked {
  background: #02aab0;
}

.checkbox-input:checked::before {
  content: "";
  content: "";
  display: block;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 13px;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.checked {
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
.replyTextarea {
  text-align: right;
}
.bodyContainer {
  height: calc(
    78vh - 100px
  ); // Adjust this height based on your header/footer height
  display: flex;
  flex-direction: column;
}

.tableBodyContainer {
  flex: 1; // Allow this div to take the remaining space
  overflow-y: auto; // Enable vertical scrolling
}
.tableBodyContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.tableBodyContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 10px;
}
