.pzUsersMainContainer {
  display: flex;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  height: 78vh;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  width: 85vw;
}
.usersList {
  flex: 1.4;
  border-right: 1px solid #343339;
  display: flex;
  flex-direction: column;
}
.headerContainer {
  display: flex;
  gap: 11px;
  border-bottom: 1px solid #343339;
  margin-top: 20px;
  padding-bottom: 19px;
}
.searchBarContainer {
  display: flex;
  flex-grow: 1;
  border: 1px solid #cfdce5;
  border-radius: 6px;
  padding: 5px 9px 5px 24px;
  margin-left: 24px;
}
.searchBar {
  color: #b3b3b3;
  width: 100%;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.filterButtonContainer {
  display: flex;
  margin: auto 0;
  margin-right: 12px;
}
.filterButton {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.usersListsCollection {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px 15px 15px 24px;
  margin-bottom: 40px;
  height: 100%;
}

.usersListsCollection::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.usersListsCollection::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  margin-top: 40px;
  border-radius: 10px;
}
.userTransferHistoryDetails,
.usersBasicDetailsContainer {
  display: flex;
  justify-content: space-between;
  background: rgba(217, 217, 217, 0.04);
  margin-bottom: 10px;
  border-radius: 10px;
}
.usersBasicDetailsContainer {
  padding: 4px 13px 4px 21px;
}
.userTransferHistoryDetails {
  padding: 10px 18px 10px 21px;
}
.usersBasicDetailsContainer:hover {
  cursor: pointer;
  background: rgba(2, 170, 176, 0.3);
}
.usersBasicDetailsContainerIfClicked {
  background: rgba(2, 170, 176, 0.3);
}
.userNameAndEmail {
  display: flex;
  flex-direction: column;
}
.userName {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.userEmail {
  padding-top: 3px;
  color: #02aab0;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}
.userKycDetails {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  border-radius: 3px;
  border: 0.5px solid var(--linear-stroke-of-blug-bg, #fff);
  padding: 5px 9px 5px 5px;
  width: 100px;
}
.kycStatus {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 9px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.kycStatusIcon {
  display: flex;
  margin: auto 0;
}
.kycIcon {
  width: 12px;
  height: 12px;
}
.paginationContainer {
  border-top: 1px solid #343339;
  display: flex;
  justify-content: space-between;
  padding: 15px 12px 8px 24px;
}
.searchPage {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  width: 77px;
  background: transparent;
  padding: 7px 8px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 16px;
}
.search {
  font-family: "Lato";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.usersDetails {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.usersDetailsMainContainer {
  padding: 18px 21px 20px 35px;
  display: flex;
  flex-direction: column;
  height: 100%; /* Allow it to take full height */
}
.userSinceContainer {
  display: flex;
  margin: auto 0;
}
.transferHistoryKey,
.customerGuidKey,
.documentDetailsKeys,
.addressDetailsKeys,
.usersDetailsKeys {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-weight: 400;
  line-height: normal;
}
.usersDetailsKeys {
  margin-bottom: 3px;
}
.userSinceValue,
.documentDetailsValues,
.fullNameValue,
.addressDetailsValues,
.usersDetailsValues {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 19px;
}
.fullNameValue {
  font-size: 16px;
}
.transferHistoryKey,
.usersDetailsKeys {
  font-size: 14px;
}
.userSinceValue,
.usersDetailsValues {
  font-size: 13px;
}
.userSinceValue {
  padding-left: 4px;
}
.transferHistoryKey {
  margin-bottom: 16px;
}
.customerGuidKey,
.documentDetailsKeys,
.documentDetailsValues {
  font-size: 11px;
}
.customerGuidKey {
  margin-bottom: 3px;
}
.documentDetailsValues {
  line-height: 16px;
}
.addressDetailsValues,
.addressDetailsKeys {
  font-size: 12px;
}
.usersDetailsHeader,
.documentDetailsContainer,
.usersEstablishedDetails {
  display: flex;
  justify-content: space-between;
}
.usersEstablishedDetails {
  margin-top: 16px;
  padding-bottom: 20px;
  border-bottom: 1px solid #343339;
}
.personalDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.adddressContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.transferIdDetails,
.dobContainer,
.cybridEmailContainer,
.addressDetails {
  display: flex;
  align-items: center;
  gap: 4px;
}
.transferDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}
.totalTransfersCount {
  color: var(--yellow, #f3e445);
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
}
.totalTransfersAmount {
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  background: var(
    --Green-grad,
    linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.documentDetailsContainer {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #343339;
}
.documentTypeAndId,
.emailAndDobContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.documentTypeAndId {
  justify-content: space-between;
  text-align: right;
}
.transferHistoryDetailsContainer {
  margin-top: 11px;
  overflow-y: auto; /* Enable scrolling for userTransactionsContainer */
  margin-bottom: 80px;
}
.recipientName {
  display: flex;
  gap: 3px;
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.userTransactionsContainer {
  padding-right: 26px;
}
.transferDetailsRight {
  text-align: right;
}
.usdAmount {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}
.transactionTimestamp {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.usdFlagIcon {
  width: 18px;
}
.transferHistoryDetailsContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.transferHistoryDetailsContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 10px;
}
.transferPaginationContainer {
  border-top: 1px solid #343339;
  display: flex;
  justify-content: space-between;
  padding: 15px 21px 8px 35px;
  flex-shrink: 0; /* Prevent this container from shrinking */
  position: sticky; /* Make it sticky at the bottom */
  bottom: 0; /* Stick it at the bottom */
}
.nextButton,
.previousButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
  border-radius: 4px;
  color: #99b2c6;
}
.nextButton {
  padding: 7px 50px;
}
.previousButton {
  padding: 7px 38px;
}
.nextButton:hover,
.previousButton:hover {
  color: #fff;
}
.pageNumber {
  font-family: "metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  color: #ffffff;
  margin: auto;
  text-align: center;
  @media screen and (max-width: 390px) {
    font-size: 12px;
  }
}
.disabledButton {
  cursor: not-allowed;
  background: #212c3d;
}
.disabledButton:hover {
  color: #99b2c6;
}
.copyIcon {
  width: 25px;
  height: 16px;
}
.copyIconSmall {
  width: 20px;
  height: 14px;
}
.copyIconSmall:hover,
.copyIcon:hover {
  cursor: pointer;
}
.valueAndCopyIconContainer {
  display: flex;
  align-items: center;
}
.copyIconSmall:hover path,
.copyIcon:hover path {
  color: #02aab0;
  stroke: #02aab0;
}
