.changeStatus {
  padding-top: 20px;

  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding-bottom: 32px;
  text-align: center;
}
.radioButtonContainer {
  margin-bottom: 34px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  gap: 20px;
  margin-left: 30px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 14px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
.cancelButton {
  padding: 6px 0px;
  width: 50%;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fd5252;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border-radius: 4px;
  cursor: pointer;
}
.updateButton {
  padding: 6px 0px;
  width: 50%;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #02aab0;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border-radius: 4px;
  cursor: pointer;
}
.errorMessage {
  color: #fd5252;
  text-align: center;
  padding-bottom: 20px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
}
