.pzUsersMainContainer {
  display: flex;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  height: 78vh;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  width: 85vw;
}
.usersList {
  flex: 1.5;
  border-right: 1px solid #343339;
  display: flex;
  flex-direction: column;
}
.headerContainer {
  display: flex;
  gap: 11px;
  border-bottom: 1px solid #343339;
  margin-top: 20px;
  padding-bottom: 19px;
}
.searchBarContainer {
  display: flex;
  flex-grow: 1;
  border: 1px solid #cfdce5;
  border-radius: 6px;
  padding: 5px 9px 5px 24px;
  margin-left: 24px;
}
.searchBar {
  color: #b3b3b3;
  width: 100%;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.filterButtonContainer {
  display: flex;
  margin: auto 0;
  margin-right: 12px;
}
.filterButton {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.usersListsCollection {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 15px 15px 24px;
  margin-bottom: 40px;
  height: 100%;
}
.emailTemplates::-webkit-scrollbar,
.usersListsCollection::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.emailTemplates::-webkit-scrollbar-thumb,
.usersListsCollection::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  margin-top: 40px;
  border-radius: 10px;
}
.templatesDetails,
.usersBasicDetailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(217, 217, 217, 0.04);
  margin-bottom: 10px;
  border-radius: 10px;
}
.usersBasicDetailsContainer {
  padding: 4px 13px 4px 15px;
}
.templatesDetails {
  padding: 10px 32px 10px 21px;
  cursor: pointer;
}
.usersBasicDetailsContainer:hover {
  cursor: pointer;
  background: rgba(2, 170, 176, 0.3);
}
.usersBasicDetailsContainerIfClicked {
  background: rgba(2, 170, 176, 0.3);
}
.userNameAndEmail {
  display: flex;
  flex-direction: column;
}
.userName {
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.userEmail {
  padding-top: 3px;
  color: #02aab0;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}
.userKycDetails {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  border-radius: 3px;
  border: 0.5px solid var(--linear-stroke-of-blug-bg, #fff);
  padding: 5px 9px 5px 5px;
  width: 100px;
}
.kycStatus {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-family: "Metrophobic";
  font-size: 9px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.kycStatusIcon {
  display: flex;
  margin: auto 0;
}
.kycIcon {
  width: 12px;
  height: 12px;
}
.paginationContainer {
  border-top: 1px solid #343339;
  display: flex;
  justify-content: space-between;
  padding: 15px 12px 8px 24px;
}
.searchPage {
  border: 1px solid #cfdce5;
  border-radius: 6px;
  width: 77px;
  background: transparent;
  padding: 7px 8px;
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-left: 16px;
}
.search {
  font-family: "Lato";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.usersDetails {
  flex: 2;
  padding: 25px 19px 20px 32px;
}
.kycFilterAndExportContainer {
  display: flex;
  justify-content: space-between;
}
.buttonContainer {
  display: flex;
  gap: 30px;
}
.exportButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "metrophobic";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border-radius: 4px;
  color: #99b2c6;
  padding: 7px 20px;
}
.exportButton:hover {
  color: #fff;
}
.dateFilterContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.dropdownContainer {
  display: flex;
}
.datePicker {
  padding: 10px 16px;
  border: 1px solid #02aab0;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #02aab0;
  }
  &::placeholder {
    font-size: 18px;
  }
}
.filterOptions {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.datePickerContainer {
  display: flex;
  gap: 60px;
}
.noMatchedUsers {
  font-family: "metrophobic";
  color: #fff;
  font-size: 16px;
}
.usersHavingTransaction {
  font-family: "metrophobic";
  color: #fff;
  font-size: 16px;
  font-weight: 400px;
  line-height: 21px;
  margin-bottom: 10px;
}
