.popUpContent {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-family: "metrophobic";
  font-weight: 400;
  color: #ffffff;
  margin-top: 45px;
}
.popUpButtonDiv {
  margin-right: 21px;
  margin-left: 21px;
  margin-top: 45px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
}
.cancelButton,
.updateButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  height: 35px;
  cursor: pointer;
  color: #ffffff;
  margin-right: 15px;
  border-radius: 4px;
}
.cancelButton:hover {
  color: #fd5252;
}
.updateButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  height: 35px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
}
.updateButton:hover {
  color: #02aab0;
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  color: #fd5252;
  text-align: center;
  margin-top: 20px;
}
