.dropdownContainer {
  position: relative;
  border-radius: 5px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #99b2c6;
  @media screen and (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
  }
}
.dropdownContainer:hover {
  cursor: pointer;
}
.dropdownTool {
  width: 10px;
  margin-left: 45px;
}
.dropdownInput {
  width: 400px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  border: 1px solid #02aab0;
  border-radius: 5px;

  @media screen and (max-width: 992px) {
    height: 45px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.dropdownMenu {
  width: 450px;
  position: absolute;
  left: 0;
  right: 0;
  margin-bottom: 10px;
  padding-top: 19px;
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d43 100%);
  margin-top: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  max-height: 230px; /* Adjust this value as needed */
  overflow-y: auto;
  z-index: 1;
  @media screen and (max-width: 992px) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.dropdownMenu::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 10px;
}
.dropdown-item {
  padding-left: 36px;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
  @media screen and (max-width: 992px) {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 16px;
  }
}
.dropdown-item:hover {
  background: #223f56;
  color: #00cdac;
}
.searchInput {
  padding-left: 36px;
  padding-bottom: 10px;
  width: 390px;
}
.noOptions {
  padding-left: 36px;
}
