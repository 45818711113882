html {
  overflow: auto;
}
.container {
  background-image: #06152b;
}

.navbar {
  display: flex;
  align-items: center;
}

.navLinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navLinks li {
  margin-right: 1rem;
}

.routes {
  flex-grow: 1;
}
.fetchUSDTToSendContainer {
  width: 554px;
  margin-top: 31px;
  margin-right: auto;
  margin-left: auto;
}
.fetchUSDTToSendContainerMob {
  width: 88%;
}
.footerDiv {
  position: fixed;
  bottom: 26px;
}

.ferchUserDetailsContainer {
  margin-top: 60px;
  width: 554px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 70px;
}
.ferchUserDetailsContainerMob {
  width: 88%;
}

.nextIcon {
  padding-left: 6px;
}
.navBar {
  background: #06152b;
  border-right: 1px solid #343339;
  height: 100vh;
  padding-top: 35px;
  position: fixed;
  top: 103px;
  z-index: 3;
}
.navBar:hover {
  color: #02aab0;
}
.navBarItems {
  text-align: center;

  margin-bottom: 20px;
}
.navList {
  list-style-type: none;
  padding: 0;
}

.navLink {
  text-decoration: none;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  width: 115px;
  height: 35px;
  color: #a5a2b8;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
}
.navLink:hover {
  color: #02aab0;
}
.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #06152b;
  z-index: 9;
}

.mobileMenu ul {
  padding: 0;
}

.mobileMenu li {
  margin-bottom: 40px;
  list-style-type: none;
}

.mobileMenu a {
  font-family: "Jura";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: white;
  margin-left: 0;
  margin-right: 0;
  width: 100px;
}

.mobileMenu a:hover {
  color: #02aab0;
}
.navBarMobile {
  display: flex;
  justify-content: flex-start;
  margin-top: 38px;
  border-bottom: 1px solid #343339;
  margin-bottom: 0;
}
.activeNavLink {
  color: #02aab0;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  border-radius: 5px;
}
.activeNavLinkMobile {
  color: #02aab0;
}
.stripePaymentContainer {
  margin-top: 60px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 70px;
}
// .routesContainer {
//   padding-bottom: 20px;
//   padding-left: 260px;
//   padding-right: 15px;
//   padding-top: 175px;
//   // width: 100%;
//   overflow: hidden;
//   scrollbar-width: thin; /* For Firefox */
//   scrollbar-color: transparent transparent; /* For Firefox */
//   scrollbar-face-color: transparent; /* For Internet Explorer */
//   scrollbar-base-color: transparent; /* For Internet Explorer */
//   @media screen and (max-width: 990px) {
//     padding-right: 15px;
//     padding-left: 15px;
//     width: 100%;
//     padding-top: 60px;
//   }
// }
.routesContainer {
  padding-bottom: 20px;
  padding-left: 180px;
  padding-right: 15px;
  padding-top: 140px;
  // overflow: auto; /* Enable scrolling */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  position: relative; /* Make the container a positioning context */

  /* Add custom scrollbar styling for WebKit browsers */
  &::-webkit-scrollbar {
    width: 0.5em; /* Width of the invisible scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the invisible scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color of the invisible scrollbar thumb */
  }

  @media screen and (max-width: 990px) {
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    padding-top: 60px;
  }
}

.routesInMobile {
  padding: 20px;
  padding-right: 20px;
}
.fetchUSDContainer {
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 80px;
}
.spinnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Metrophobic";
  width: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
input:focus {
  outline: none;
}
input::placeholder {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: rgba(153, 178, 198, 1);
}
th {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  height: 50px;
  text-align: left;
  padding-left: 20px;
}
td {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
  margin-bottom: 31px;
  padding-left: 20px;
  height: 50px;
}
table {
  border-radius: 0px 0px 10px 10px;
  height: auto;
  padding-bottom: 20px;
  padding-right: 12px;
  width: 100%;
}
