.PZTransfersContainers {
  display: flex;
  gap: 65px;
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  padding-top: 10px;
  padding-right: 46px;
  padding-left: 20px;
  padding-bottom: 5px;
  justify-content: space-between;
  border-radius: 10px;
}
.supplyHeadings {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 5px;
}
.supplyValues {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #02aab0;
  margin: 0;
}
