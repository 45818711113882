.pzPendingRequestsMainContainer {
  background: linear-gradient(180deg, #243349 0%, #101d2e 100%);
  height: 78vh;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  width: 85vw;
}
.headerContainer {
  padding: 16px 20px 16px 32px;
  border-bottom: 1px solid #343339;
  color: var(--white, var(--linear-stroke-of-blug-bg, #fff));
  font-family: "Metrophobic";
  font-size: 28px;
  font-weight: 400;
}
.bodyContainer {
  padding: 31px 8px 20px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.headingKeys {
  display: flex;
  color: #a5a2b8;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px;
  margin-left: 11px;
  margin-right: 31px;
}
.valuesContainer {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 120px;
}
.valuesContainer::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.valuesContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(47deg, #02aab0 5.98%, #00cdac 85.82%);
  border-radius: 10px;
}
.pzPendingRequestsTransactions {
  padding: 8px 11px;
  background: rgba(217, 217, 217, 0.04);
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.dateAndTimeValue,
.transferIdValue,
.customerGuidValue,
.senderNameValue,
.beneficiaryNameValue,
.amountValue,
.accountNoValue,
.ifscCodeValue {
  color: var(--linear-stroke-of-blug-bg, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Metrophobic";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dateAndTimeValue {
  font-size: 12px;
}
.resendButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #2f3c51 0%, #1f2d43 100%);
  font-family: "metrophobic";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  padding: 4px 8px;
}
.resendButton:hover {
  color: #02aab0;
}
.dateAndTimekey,
.transferIdkey,
.customerGuidKey,
.dateAndTimeValue,
.transferIdValue,
.customerGuidValue {
  width: 9%;
}
.amountkey,
.amountValue {
  width: 10%;
}
.senderNamekey,
.beneficiaryNamekey,
.beneficiaryNameValue,
.senderNameValue {
  width: 15%;
}
.accountNokey,
.ifscCodekey,
.accountNoValue,
.ifscCodeValue {
  width: 12%;
}
.accountNoValue,
.accountNokey {
  width: 15%;
}
.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1;
}
.tooltip:hover::before {
  visibility: visible;
  opacity: 1;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 35%;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
.buttonContainer {
  width: 12%;
}
.resendButtonConatiner {
  width: 12%;
}
.disabledButton {
  cursor: not-allowed;
  background: #212c3d;
  color: #99b2c6;
}
.disabledButton:hover {
  color: #99b2c6;
}
