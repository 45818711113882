.popUpContent {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-family: "metrophobic";
  font-weight: 400;
  color: #ffffff;
  margin-top: 45px;
}
.popUpButtonDiv {
  margin-right: 21px;
  margin-left: 21px;
  margin-top: 45px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
}
.cancelButton,
.updateButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  cursor: pointer;
  color: #ffffff;
  margin-right: 15px;
  border-radius: 4px;
}
.cancelButton:hover {
  color: #fd5252;
}
.updateButton {
  display: flex;
  justify-content: center;
  gap: 4px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
}
.updateButton:hover {
  color: #02aab0;
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  color: #fd5252;
  text-align: center;
  margin-top: 20px;
}
.spinnerOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.spinnerContainer {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.disabledButton {
  cursor: not-allowed;
  background: #212c3d;
  color: #99b2c6;
}
.disabledButton:hover {
  color: #99b2c6;
}
